@font-face {
    font-family: 'Futura';
    font-style: normal;
    src: local('Futura'), url("./fonts/Futura Bk Book.ttf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: 'Futura Light';
    font-style: normal;
    src: local('Futura Light'), url("./fonts/Futura Lt Light.ttf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: 'Futura-Medium';
    src: local('Futura-Medium'), url("./fonts/Futura-Medium.ttf") format("opentype");
    font-style: normal;
    font-weight: 400;
}

html {
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, Futura Light, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #e5e8ef;
    display: flex;
    justify-content: center;
    height: 100%;
}

h2 {
    text-align: center;
}

label {
    display: block;
    padding-top: 1rem;
    padding-bottom: 0.15rem;
    font-family: "Futura Light", sans-serif;
}

input {
    width: 100%;
    padding: 0.25rem;
    border: none;
    outline: none;
    transition: border-bottom-color 200ms ease-in-out;
    border-bottom: 1px solid #eee;
    font-family: "Futura Light", sans-serif;
}

input:focus {
    transition: border-bottom-color 200ms ease-in-out;
    border-bottom: 1px solid #2ecbde;
}

button, .redirect-button {
    margin-top: 2rem;
    background: #2ecbde;
    border: none;
    padding: 0 20px;
    font-family: "Futura-Medium", sans-serif;
    color: white;
    border-radius: 16px;
    line-height: 32px;
    cursor: pointer;
    text-decoration: none;
}

header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#root {
    height: 100vh;
    width: 100vw;
}