.App {
    text-align: left;
}

.logo-image {
    width: 50%;
    text-align: center;
}

.login-form {
    background: #ffffff;
    display: block;
    padding: 40px;
    margin: 150px auto;
    border: 1px solid #efefef;
    box-shadow: 0 10px 20px #aaa;
    border-radius: 10px;
    width: 400px;
}

.button-holder {
    display: flex;
    justify-content: center;
}

.error-holder {
    margin-top: 1rem;
    background: #f7a0a0;
    padding: 0.125rem 1rem;
    border-radius: 10px;
}

@media screen and (max-width: 576px) {
    .login-form {
        display: block;
        margin: 40% auto;
        width: 80%;
        padding: 60px 20px 20px 20px;
    }

}